// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "s_ry d_gs d_cr";
export var heroHeaderCenter = "s_gt d_gt d_cr d_dv";
export var heroHeaderRight = "s_gv d_gv d_cr d_dw";
export var heroParagraphLeft = "s_rz d_gp d_cv";
export var heroParagraphCenter = "s_gq d_gq d_cv d_dv";
export var heroParagraphRight = "s_gr d_gr d_cv d_dw";
export var heroBtnWrapperLeft = "s_rB d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "s_rC d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "s_rD d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "s_rF d_gn d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "s_rG d_gm d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "s_rH y_rH";
export var heroExceptionNormal = "s_rJ y_rJ";
export var heroExceptionLarge = "s_rK y_rK";
export var Title1Small = "s_rL y_rL y_sv y_sw";
export var Title1Normal = "s_rM y_rM y_sv y_sx";
export var Title1Large = "s_rN y_rN y_sv y_sy";
export var BodySmall = "s_rP y_rP y_sv y_sP";
export var BodyNormal = "s_rQ y_rQ y_sv y_sQ";
export var BodyLarge = "s_rR y_rR y_sv y_sR";