// extracted by mini-css-extract-plugin
export var articleWrapper = "n_p5";
export var articleText = "n_p6 d_dt d_cr";
export var header = "n_p7 d_Y d_v";
export var headerImageWrapper = "n_p8 d_bc d_Z d_7 d_6 d_3 d_8 d_bj d_bQ";
export var headerGradient = "n_p9 d_bc d_Z d_7 d_6 d_3 d_8";
export var headerGradientOverlay = "n_qb d_bc d_Z d_7 d_6 d_3 d_8";
export var headerContentWrapper = "n_qc d_by d_bN d_bC d_bH d_bk d_Y";
export var headerContentWrapperAlt = "n_qd d_by d_bN d_bC d_bH d_bk d_Y";
export var contentWrapper = "n_qf d_v";
export var dateTag = "n_qg d_cd d_cq d_dp d_v";
export var icon1 = "n_qh d_cN";
export var icon2 = "n_qj d_cN d_cH";
export var tagBtn = "n_qk d_b0 d_cC d_dn d_dz";
export var headerText = "n_ql d_v d_cd d_cr d_bV";
export var center = "n_qm d_dv";
export var videoIframeStyle = "n_qn d_d4 d_v d_G d_bx d_b0 d_Q";
export var articleImageWrapper = "n_qp d_cr d_Y";
export var articleImageWrapperIcon = "n_qq d_cr d_Y";
export var articleRow = "n_qr d_bC";
export var quoteWrapper = "n_gN d_bB d_bN d_cr";
export var quoteBar = "n_qs d_G";
export var quoteText = "n_qt";
export var authorBox = "n_qv d_v";
export var authorRow = "n_qw d_bC d_bV d_cx";
export var separator = "n_qx d_v";
export var line = "n_fj d_v d_fj d_cX d_cx";
export var authorImage = "n_lm d_b6 d_Y d_v d_cs";
export var authorText = "n_qy d_cg d_cs";
export var masonryImageWrapper = "n_qz";
export var bottomSeparator = "n_qB d_v d_cx";
export var linksWrapper = "n_qC d_cg d_dt";
export var comments = "n_qD d_bC d_cx";
export var sharing = "n_qF d_by d_bC d_bH";
export var shareText = "n_qG d_v d_dv";
export var icon = "n_qH";
export var customRow = "n_qJ d_bC d_Y";
export var text = "n_qK";
export var SubtitleSmall = "n_qL";
export var SubtitleNormal = "n_qM";
export var SubtitleLarge = "n_qN";