// extracted by mini-css-extract-plugin
export var tileContent = "w_r4 d_v d_G d_Y";
export var teamTextLeft = "w_r5 d_dt";
export var teamTextCenter = "w_r6 d_dv";
export var teamTextRight = "w_r7 d_dw";
export var alignLeft = "w_qP d_v d_by d_fn d_bF d_dt";
export var alignCenter = "w_bN d_v d_by d_fp d_bC d_dv";
export var alignRight = "w_qQ d_v d_by d_fq d_bG d_dw";
export var teamContainer = "w_r8 d_dV";
export var teamContainerFull = "w_r9 d_dS";
export var teamRowWrapper = "w_sb d_cb";
export var teamTileWrapper = "w_j1 d_j1 d_Y d_ct";
export var teamTileSquareWrapper = "w_sc d_j2 d_Y d_ct";
export var teamTileRoundWrapper = "w_j2 d_j2 d_Y d_ct";
export var teamTileNoGuttersWrapper = "w_j3 d_j3 d_Y";
export var teamHoverNoGutters = "w_j4 d_j4 d_Z d_v d_G d_by d_bC d_bN d_bl";
export var teamImageText = "w_j9 d_j9 d_bk d_v d_bx";
export var teamInfoWrapperSquare = "w_sd d_j5";
export var teamInfoWrapperRound = "w_j5 d_j5";
export var teamInfoWrapper = "w_sf d_j6 d_Z";
export var teamInfoWrapperNoGutters = "w_j7 d_j7 d_v d_G d_bC d_bL d_bH d_c6";
export var teamImgWrapper = "w_jX d_jX";
export var teamImgWrapperAlt = "w_jY d_jY";
export var teamImgWrapperNoGutters = "w_j8 d_j8";
export var teamHeader = "w_sg d_cv";
export var teamHeaderAlt = "w_sh d_cv";
export var teamHeaderNoGutters = "w_sj d_cv d_cC";