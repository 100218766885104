// extracted by mini-css-extract-plugin
export var lbContainerOuter = "z_tD";
export var lbContainerInner = "z_tF";
export var movingForwards = "z_tG";
export var movingForwardsOther = "z_tH";
export var movingBackwards = "z_tJ";
export var movingBackwardsOther = "z_tK";
export var lbImage = "z_tL";
export var lbOtherImage = "z_tM";
export var prevButton = "z_tN";
export var nextButton = "z_tP";
export var closing = "z_tQ";
export var appear = "z_tR";