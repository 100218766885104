// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "x_sk d_bC";
export var storyRowWrapper = "x_hv d_hv d_bJ";
export var storyLeftWrapper = "x_sl d_by d_bN";
export var storyWrapperFull = "x_sm d_cC";
export var storyWrapperFullLeft = "x_nL d_cC d_by d_bN d_bC";
export var contentWrapper = "x_qf d_hw";
export var storyLeftWrapperCenter = "x_sn d_hx d_by d_bC d_bN d_bH";
export var storyRightWrapperCenter = "x_sp d_hC";
export var storyHeader = "x_sq d_hB d_v d_cr";
export var storyHeaderCenter = "x_hz d_hz d_v d_cr d_dv d_bx";
export var storyParagraphCenter = "x_hy d_hy d_bx d_dv";
export var storyBtnWrapper = "x_sr d_d1 d_d0 d_v d_by d_bF";
export var storyBtnWrapperCenter = "x_hG d_hG d_d0 d_v d_by d_bC";
export var imageWrapper = "x_ss d_ff d_Y";
export var imageWrapperFull = "x_st d_v d_G d_bd d_Y";