// extracted by mini-css-extract-plugin
export var alignDiscLeft = "q_qT d_fn d_bF d_dt";
export var alignLeft = "q_qP d_fn d_bF d_dt";
export var alignDiscCenter = "q_qV d_fp d_bC d_dv";
export var alignCenter = "q_bN d_fp d_bC d_dv";
export var alignDiscRight = "q_qW d_fq d_bG d_dw";
export var alignRight = "q_qQ d_fq d_bG d_dw";
export var footerContainer = "q_qX d_dV d_bV";
export var footerContainerFull = "q_qY d_dS d_bV";
export var footerHeader = "q_kc d_kc";
export var footerTextWrapper = "q_qZ d_v";
export var footerDisclaimerWrapper = "q_kk d_kk d_cj";
export var badgeWrapper = "q_q0 d_v d_by d_bN d_bD d_bH";
export var footerDisclaimerRight = "q_q1 undefined";
export var footerDisclaimerLeft = "q_q2 undefined";
export var verticalTop = "q_q3 d_by d_bH d_bM d_bK";
export var firstWide = "q_q4";
export var disclaimer = "q_q5 d_by d_bH";
export var socialDisclaimer = "q_q6";
export var left = "q_q7";
export var wide = "q_q8 d_cx";
export var right = "q_q9 d_bJ";
export var line = "q_fj d_fk d_ct";
export var badgeDisclaimer = "q_rb d_bB d_bN d_bH";
export var badgeContainer = "q_rc d_by d_bG d_bN";
export var badge = "q_rd";
export var padding = "q_rf d_c6";
export var end = "q_rg d_bG";
export var linkWrapper = "q_rh d_dz";
export var link = "q_mz d_dz";
export var colWrapper = "q_rj d_cw";
export var consent = "q_f d_f d_bB d_bN";
export var media = "q_rk d_bw d_dx";
export var itemRight = "q_rl";
export var itemLeft = "q_rm";
export var itemCenter = "q_rn";
export var exceptionWeight = "q_rp y_sX";