// extracted by mini-css-extract-plugin
export var galleryMasonryImageWrapper = "r_jN d_jN d_ct";
export var galleryMasonryImage = "r_jM d_jM d_v d_bQ d_dz";
export var alignLeft = "r_qP d_fn d_bF d_dt";
export var alignCenter = "r_bN d_fp d_bC d_dv";
export var alignRight = "r_qQ d_fq d_bG d_dw";
export var galleryContainer = "r_rq d_dV";
export var galleryContainerFull = "r_rr d_dS";
export var galleryRowWrapper = "r_rs d_cb";
export var galleryGuttersImage = "r_jQ d_jQ d_J d_ct";
export var galleryNoGuttersImage = "r_jP d_jP d_J d_cC";
export var galleryTextGutters = "r_jK d_jK d_cv";
export var galleryTextNoGutters = "r_jL d_jL d_cv";
export var galleryTextMasonry = "r_rt d_jK d_cv";
export var galleryImageWrapper = "r_rv d_ff d_Y";
export var descText = "r_rw d_jR d_Z d_8 d_6 d_7 d_m";
export var guttersDesc = "r_rx";