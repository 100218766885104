// extracted by mini-css-extract-plugin
export var alignLeft = "p_qP d_fn d_bF d_dt";
export var alignCenter = "p_bN d_fp d_bC d_dv";
export var alignRight = "p_qQ d_fq d_bG d_dw";
export var FAQMainHeader = "p_ks d_ks d_v d_bx";
export var FAQComponentHeader = "p_ky d_ky d_bw";
export var FAQComponentParagraph = "p_kt d_kt d_cC";
export var FAQComponentsWrapper = "p_kq d_kq d_cm d_cK d_cQ d_c1 d_cW d_dz";
export var FAQComponentsWrapperClean = "p_kr d_kr d_cm d_cK d_cQ d_cZ d_dz";
export var accordion__body = "p_kz d_kz d_bx";
export var accordion__bodyHidden = "p_kC d_kC d_bv d_dk";
export var FAQDivider = "p_kp d_kp";
export var accordionWrapper = "p_qR d_cf";
export var accordion__item = "p_qS d_cK";
export var accordion_arrow = "p_kG d_kG";
export var accordion_arrow_open = "p_kJ d_kJ";
export var accordion_arrow_2 = "p_kL d_kL";
export var accordion_arrow_2_open = "p_kN d_kN";